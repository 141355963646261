<template>
  <div ref="treeSelect">

    <van-tree-select
      v-if="options && options.items && options.items.length !== 0"
      :height="itmeHeight+'px'"
      :items="options.items"
      :main-active-index.sync="options.active"
      @click-nav="options.clickNav"
    >
      <template #content>
        <slot />
      </template>
    </van-tree-select>
  </div>
</template>

<script>

import Vue from 'vue'
import { List, TreeSelect } from 'vant'

Vue.use(TreeSelect).use(List)

export default {
  name: 'TreeSelect',
  components: {
  },
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          active: 0,
          clickNav: () => {
          },
          items: [],
          id: ''
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      itmeHeight: 0,
      itmeTopHeight: 0

    }
  },
  mounted() {
    this.getItmeHeight()
    window.onresize = () => {
      return this.getItmeHeight()
    }
  },
  methods: {
    // changePage(data) {
    //   this.options.goodsParams.changePage(data);
    // },
    getItmeHeight() {
      this.itmeTopHeight = this.$refs.treeSelect.getBoundingClientRect().top
      this.itmeHeight = this.windowHeight - this.itmeTopHeight
    }
  }
}
</script>
<style lang="less" scoped>
.van-sidebar {
  flex: .75

}

// .van-sidebar{
// flex：1
// }
</style>
